<script setup lang="ts">
withDefaults(
	defineProps<{
		location: "account" | "sidebar";
		progress: string;
		level: number;
		nextLevel: number;
	}>(),
	{
		location: "account"
	}
);

const { t } = useT();
const { open } = useFunrizeModals();
const { enabled, rankLeague, isConfirmedProfile, appInitData } = useStatusSystem();
const popperText = t(
	"Don't worry! Your progress in Funrize Club is frozen but will be restored once you finish Hawaii Adventure Season."
);

const handleClickUnlockStatusModal = () => {
	open("LazyOModalProfileRewards");
};
</script>
<template>
	<div :class="['progress', `box-${location}`]">
		<div class="box-progress">
			<div class="progress-bar">
				<div class="filled-progress" :style="{ width: progress }">
					<APopper
						v-if="appInitData?.season?.isActive && location === 'account'"
						class="popper-lock"
						placement="top"
						arrow
						:content="popperText"
						offset-distance="0"
					>
						<LazyNuxtImg src="/nuxt-img/status-system/lock.png" alt="lock" width="88" height="94" format="webp" />
					</APopper>
				</div>
			</div>
			<template v-if="isConfirmedProfile">
				<NuxtIcon v-if="location === 'account'" name="28/gift" filled class="icon" />
				<APopper v-else class="tooltip" :content="t('Play on - Your Prize Awaits')" placement="top">
					<NuxtIcon name="28/gift" filled class="icon" />
				</APopper>
			</template>
			<NuxtImg
				v-else
				class="icon"
				src="/nuxt-img/status-system/lock-progress.png"
				width="28"
				height="28"
				format="webp"
				alt="lock-progress"
			/>
		</div>

		<template v-if="location === 'account'">
			<div class="level-row">
				<AText as="div" variant="toledo">
					<i18n-t keypath="Level {key}">
						<template #key>
							<AText variant="toledo" :modifiers="['bold']" class="text-cannes">
								{{ level || 1 }}
							</AText>
						</template>
					</i18n-t>
				</AText>
				<AText v-if="isConfirmedProfile" as="div" variant="toledo">
					<i18n-t keypath="Level {key}">
						<template #key>
							<AText variant="toledo" :modifiers="['bold']" class="text-cannes">
								{{ nextLevel || 2 }}
							</AText>
						</template>
					</i18n-t>
				</AText>
				<AText
					v-else
					variant="toledo"
					:modifiers="['underline', 'link']"
					data-tid="unlock-rewards"
					@click="handleClickUnlockStatusModal"
				>
					{{ t("Unlock rewards") }}
				</AText>
			</div>
		</template>
		<template v-if="location === 'sidebar'">
			<div class="level-row">
				<AText
					v-if="enabled"
					class="pointer"
					variant="topeka"
					:modifiers="['underline']"
					@click="open('LazyOModalRewardsStatusSystem')"
				>
					{{ t("Funrize Club rewards") }}
				</AText>
				<AText v-else-if="!rankLeague?.enabled" variant="topeka">
					{{ t("Funrize Club - coming soon") }}
				</AText>
				<AText v-if="rankLeague?.enabled" as="div" variant="topeka" class="level_txt">
					<i18n-t keypath="Level {key}">
						<template #key>
							<AText variant="topeka" :modifiers="['bold']" class="text-cannes">
								{{ nextLevel || 2 }}
							</AText>
						</template>
					</i18n-t>
				</AText>
			</div>
			<AButton
				v-if="!rankLeague?.enabled && !isConfirmedProfile"
				variant="primary"
				size="xs"
				class="btn"
				@click="handleClickUnlockStatusModal"
			>
				<AText variant="ternopil" :modifiers="['uppercase']" class="text-cannes">
					{{ t("Unlock Funrize Club") }}
				</AText>
			</AButton>
		</template>
	</div>
</template>
<style scoped lang="scss">
.progress {
	display: flex;
	flex-direction: column;
	gap: gutter(0.5);
}

.level-row {
	display: flex;
	justify-content: space-between;
}

.box-progress {
	display: flex;
	align-items: center;

	.tooltip {
		display: inline-flex;
		margin: 0 0 0 gutter(-1) !important;
		border: 0 !important;
		flex-shrink: 0;
		cursor: pointer;

		&:deep(> div) {
			display: inline-flex;
		}

		.icon {
			margin: 0;
		}
	}
}
.progress-bar {
	display: flex;
	border-radius: 50px;
	border: 1px solid var(--cagli);
	padding: 1px;
	height: 14px;
	width: 100%;
	background: var(--chico);
}

.filled-progress {
	border-radius: 12px;
	height: 100%;
	min-width: 12px;
	background: var(--gloucester);
	transition: width 0.3s ease;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		right: 1px;
		width: 10px;
		height: 10px;
		border-radius: 12px;
		opacity: 0.6;
		background: var(--cannes);
		filter: blur(2px);
	}

	.popper-lock {
		position: absolute;
		right: -10px;
		top: -17px;
		z-index: 99;
		cursor: pointer;

		&:deep(.popper) {
			width: 200px;
			padding: gutter(1);
			color: var(--cannes);
			text-align: center;
			font-size: 12px;
			font-weight: 700;
			line-height: 16px;
		}

		img {
			width: 32px;
			height: 32px;
		}
	}
}

.icon {
	display: inline-flex;
	font-size: 52px;
	flex-shrink: 0;
	margin-left: gutter(-1);

	&:deep(svg) {
		margin: 0;
	}
}

.toledo {
	color: var(--cannes);
}

.locale-sidebar {
	.progress-bar {
		height: 10px;
	}

	.filled-progress::after {
		width: 6px;
		height: 6px;
	}

	.icon {
		font-size: 28px;
	}

	.level_txt {
		color: var(--ciputat);
	}

	.level-row {
		align-items: center;
	}

	.btn {
		margin-top: gutter(1);
		border-radius: 28px;
		max-height: 24px;
	}

	.pointer {
		cursor: pointer;
	}
}
</style>
